import React from "react"
import Experiences from "../experiences"

const Full = () => (
  <section>
    <h4>Full</h4>
    <section>
      <h5>LInkedIN</h5>

      <h6>Senior Consultant

        Retail Express
        Dates Employed May 2003 – Mar 2009
        Employment Duration 5 yrs 11 mos</h6>

      <p>Innovative JEE software for retailers in an agile environment.</p>

      <p>To give a flavour, I'll outline my last project. A multi-disciplinary, mixed experience agile team used test
        driven development (TDD) and behaviour driven development (BDD) to develop a high performance, data rich
        forecasting module. A responsive, multi-threaded asynchronous Swing screen connected through heavily parallel
        RMI to JEE middleware. This combined RDBMS data (accessed using Kodo JPA) with fresh calculations from map
        reduce forecasting analytics. Sounds complex, and it was - but the art lay in presenting the user with a simple,
        cohesive and response interface to this rich, high volume data. An intense experience but we pulled together and
        hit the release window.</p>

      <p>Life at a start-up was an exciting roller-coaster.</p>
      <Experiences title='Experiences' bullets={[


        { point: "Agile" },
      { point: "Extreme Programming" },
        { point: "Pair Programming" },
        { point: "Mentoring" },
      { point: "Teamwork" },
        { point: "Offshore collaboration" },
        { point: "Agile UML" },
        { point: "Design Patterns" },
        { point: "Java" },
        { point: "Python and Groovy (scripting)" },
        { point: "Swing (multi-threaded, HMVC)" },
        { point: "JEE on Weblogic and WebSphere (EJB, JMS, JCA)" },
        { point: "SOAP over HTTP with WSDL on Axis 1 and ActiveSOAP" },
        { point: "CVS then Subversion" },
        { point: "Kodo JDO against Oracle, DB2 and some MySQL" },
        { point: "IoC with PicoContainer" },
        { point: "Ant with CruiseControl" },
        { point: "Red Hat Enterprise Linux" },
        { point: "Solaris" },
        { point: "Ubuntu Linux" },
        { point: "VMWare" },
        { point: "TDD with JUnit" },
        { point: "BDD with JMock" },
        { point: "Apache Tomcat and Jetty" },
        { point: "AOP with XDoclet and JavaAssist" },
        { point: "XML, XSLT and XmlBeans" },
        { point: "HTML and CSS with Struts" },
        { point: "Performance tuning with JProfiler" },
        { point: "MapReduce with GridGain" }]}/>
    </section>
    <section>
    <h5>[DRAFT 1]</h5>
    <p>Agile development using enterprise Java of innovative software for retailers.</p>

    <p>I resigned in March 2009 to take a break for academic study.</p>

    <p>To give a flavour fo this work, I'll outlined the last project I was involved with. Using a multi-disciplinary,
      mixed experience agile team, TDD and BDD, we developed a high performance, data rich forecasting module. A
      responsive, multi-threaded asynchronous Swing screen connected through heavily parallel RMI to JEE middleware.
      This middleware used Kodo (JPA) OR mapping to access data stored in an RDBMS and combined it with fresh
      calculations from map reduce analytics.</p>


    <h5>[DRAFT 2]</h5>
    <p>2003 till the present: Senior Consultant, Retail Express Limited</p>

    <p>As might be expected, life at a start up with a single product has been a roller coaster.</p>

    <p>AMP is head office retail software for pricing, advertising and marketing. It had been under active development
      for around 3 months when I joined the team (as it expanded to around the 15 developer mark). Around eighteen
      months later, the team was cut to 6 developers. Retail Express turned the corner in 2006
      with it's second customer. In 2007, had a rapidly expanding development team and order book.</p>

    <p>AMP is coded in Java and comprises a primary Swing GUI plus secondary web applications running against classic
      JEE middleware.</p>

    <Experiences bullets={[
      { point: "Retail Express initially practiced extreme programming. This gradually transitioned to an agile development methodology." },
      { point: "Involved in the design and implementation of all areas of the system from the Swing and web UIs though middle tier business logic to data access. The designs drew heavily on classic design patterns. Helped to move the system from an initially highly coupled and monolithic architecture to one based on modularisation, separation of concerns and inversion of control." },
      { point: "WebLogic (first 8 and then 9) is the primary target container." },
      { point: "Helped to port AMP from Weblogic to WebSphere. Developed Python and Jython installation scripts include scripting deployment of Message beans within the service bus. Used XDoclet to generate descriptors, transports and bindings from custom EJB annotations. AOP crosscuts (using JavaAssist) were used for flexible logging and application security." },
      { point: "Oracle is primary RDBMS (principally 9 and 10 with a little 8) target. Some MySQL and DB2." },
      { point: "POJO based data access using JDO." },
      { point: "Client facing stateless session beans provide services such as transaction support and security for synchronous calls. Message driven beans provide asynchronous processing. Used Web service interfaces for interoperability." },
      { point: "The primary client is a Swing application. The codebase is substantial and faced the usual numerous architectural challenges associated with large Swing applications. These were overcome by application of design patterns (such as hierarchical MVC), inversion of control and separation of concerns." },
      { point: "In the early days, lead one small web based project using Struts." },
      { point: "Designed web services  (SOAP over HTTP) used to interface AMP and a media publication system written in .NET and Adobe middleware. Implementated used ActiveSOAP and XmlBeans. One of the main challenges was dealing with off shore developers. Developed technical interoperability specifications comprising WSDL, UML and XML schema." },
      { point: "Responsible for build management using Ant. Moved to a modern declarative, modular build architecture to support modularisation. Factored monolithic application into modules and components. Replaced configuration system with flexible inversion of control using PicoContainer glued with Groovy scripting." },
      { point: "Responsible for code management using CVS and Subversion. Maintaining multiple code streams and a large code base with offshore developers proved an interesting challenge." },
      { point: "Installation and administration of Red Hat Enterpise Linux 5, Ubuntu and Solaris 10 servers. Some virtualisation using VMWare." },
    ]}
                 title='Experiences'/>

    <h5>[ORIGINAL]</h5>
    <p>2003 till the present Retail Express Limited</p>


    <p>As might be expected, life at a start up has been a roller coaster.</p>


    <p>AMP is head office retail software for pricing, advertising and marketing. It had been under active development
      for
      around 3 months when I joined the team (as it expanded to around the 15 developer mark). Around eighteen months
      later, the team was cut to 6 developers. Happily, it looks like the company has turned the corner with a queue of
      new customers and the team is expanding again.</p>


    <p>AMP is coded in Java and comprises a Swing based GUI (plus some web applications) running against classic 2JEE
      middleware.</p>

    <Experiences bullets={[
      {
        point: "Involved in the design and implementation of all areas of the system from the Swing and web UIs though middle tier " +
          "business logic to data access. The designs drew heavily on classic design patterns. Helped to move the system from " +
          "an initially highly coupled and monolithic architecture to one based on modularisation, separation of concerns and" +
          "inversion of control.",
      },
      {
        point: "Retail Express initially practiced extreme programming. This gradually transitioned to an agile development " +
          "methodology.",
      },
      {
        point: "AMP uses enterprise beans. Client facing stateless session beans provide services such as transaction support and " +
          "security for synchronous calls. Message driven beans provide asynchronous processing. Container managed transactions " +
          "are preferred though sometimes user transactions are necessary.",
      },
      { point: "WebLogic (first 8 and then 9) was the primary target container" },
      { point: "Heavily involved with the recent port to WebSphere 6.1 using Jython and XDoclet" },
      { point: "Kodo JDO formed the basic of the data access layer." },
      { point: "The primary database target was Oracle (principally 9 and 10 with a little 8) but also some MySQL and DB2." },
      {
        point: "In the early days, lead one small web based project using Struts but the main focus has always been the Swing " +
          "client. There is currently a renewed interest in web development this time using Stripes. ",
      },
      {
        point: "A Swing based GUI is the primary client. The codebase is substantial and faced the usual challenges associated with " +
          "Swing. The primary architectural pattern used to address these was hierarchical MVC. This helps to manage the " +
          "complex wiring that frequently results from component based reuse.",
      },
      {
        point: "Responsible for build management using Ant. Moved to a modern declarative, modular build architecture to support " +
          "modularisation.",
      },
      {
        point: "Replaced monolithic configuration system with flexible inversion of control using PicoContainer glued with Groovy " +
          "script.",
      },
      {
        point: "Responsible for code management using CVS and Subversion. Maintaining multiple code streams and a large code base " +
          "with offshore developers proved an interesting challenge.",
      },
      {
        point: "Web services (SOAP over HTTP) were used to interface between AMP and a media publication system written in .NET and  " +
          "Adobe middleware. This is a primarily synchronous push from AMP to the publishing software. Implementation used  " +
          "ActiveSOAP and XmlBeans. One of the main challenges was dealing with the geographically remote developers coding the  " +
          "other side of the interface so WSDL was used.",
      },
      {
        point: "Installation and administration of Red Hat Enterpise Linux 5, Ubuntu and Solaris 10 servers. Some virtualisation  " +
          "using VMWare.",
      },

      {
        point: "Porting from Weblogic to WebSphere. Developed Python and Jython installation scripts include scripting deployment  " +
          "of Message beans within service bus. Used XDoclet to generate descriptors, transports and bindings from custom EJB  " +
          "annotations. Improved and ported AOP crosscuts using Javassist for flexible logging and application security.",
      },
    ]} title='Experiences'/>

    </section>
  </section>
)

export default Full