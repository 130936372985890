import React from "react"
import Short from "./retail_express/short"
import Full from "./retail_express/full"

const RetailExpress = () => (
  <section>
    <Short/>
    <hr/>
    <Full/>
  </section>
)

export default RetailExpress