import React from "react"
import Experiences from "../experiences"

const Short = () => (
  <section>
    <h5>2003 till the present: Senior Consultant, Retail Express Limited</h5>

    <p>
      Innovative JEE software for retailers in an agile environment.
    </p>
    <p>
      To give a flavour, I'll outline my last project.
      A multi-disciplinary, mixed experience agile team used test driven development (TDD) and
      behaviour driven development (BDD) to develop a high performance, data rich forecasting module.
      A responsive, multi-threaded asynchronous Swing screen connected through heavily parallel RMI to JEE middleware.
      This combined RDBMS data (accessed using Kodo JPA) with fresh calculations from map reduce forecasting analytics.
      Sounds complex, and it was - but the art lay in presenting the user with a simple, cohesive and response interface
      to this rich, high volume data. An intense experience but we pulled together and hit the release window.
    </p>
    <p>
      Life at a start-up was an exciting roller-coaster.
    </p>
    <Experiences bullets={[
      { point: "Agile" },
      { point: "Extreme Programming" },
      { point: "Pair Programming" },
      { point: "Mentoring" },
      { point: "Teamwork" },
      { point: "Offshore collaboration" },
      { point: "Agile UML" },
      { point: "Design Patterns" },
      { point: "Java" },
      { point: "Python and Groovy (scripting)" },
      { point: "Swing (multithreaded, HMVC)" },
      { point: "JEE on Weblogic and WebSphere (EJB, JMS, JCA)" },
      { point: "SOAP over HTTP with WSDL on Axis 1 and ActiveSOAP" },
      { point: "CVS then Subversion" },
      { point: "Kodo JDO against Oracle, DB2 and some MySQL" },
      { point: "IoC with PicoContainer" },
      { point: "Ant with CruiseControl" },
      { point: "Red Hat Enterprise Linux" },
      { point: "Solaris" },
      { point: "Ubuntu Linux" },
      { point: "VMWare" },
      { point: "TDD with JUnit" },
      { point: "BDD with JMock" },
      { point: "Apache Tomcat and Jetty" },
      { point: "AOP with XDoclet and JavaAssist" },
      { point: "XML, XSLT and XmlBeans" },
      { point: "HTML and CSS with Struts" },
      { point: "Performance tuning with JProfiler" },
      { point: "MapReduce with GridGain" },
    ]} title='Experiences'/>
    <p>Life at a start up has been a roller coaster. When I joined, AMP had been under active
      development for around 3 months. Eighteen months later, the team of 15 developers was cut
      to only 6. The crucial corner was turned in 2006 with the second customer. 2007 saw rapid
      expansion of the development team and the order book.</p>
    <p>AMP is a retail head office retail software for pricing, advertising and marketing. It is coded in
      Java and comprises a primary Swing GUI (plus some secondary web applications) running
      against JEE middleware.</p>
    <ul>
      <li>Retail Express initially practiced extreme programming (including pair programming).
        This gradually gave way to an agile development methodology.
      </li>
      <li>I have been involved in the design and implementation of all areas of the system (from
        the Swing and web UIs, though business logic and analytics to data access).
      </li>
      <li>WebLogic (first 8 and then 9) was the primary target container.</li>
      <li>Helped to port AMP from Weblogic to WebSphere. Developed Python and Jython
        installation scripts include scripting deployment of Message beans within the service
        bus. Used XDoclet2 to generate descriptors, transports and bindings from custom
        annotations. Used AOP crosscuts (with JavaAssist) for flexible logging and application
        security. Ported message beans to JCA1.5 and WebSphere service bus.
      </li>
      <li>Oracle (principally 9 and 10) was the primary RDBMS target. Some MySQL and DB2.</li>
      <li>Used POJO based data access with JDO. A flexible design for the data access layer
        was required to share data with radically different customer specific data stores.
      </li>
      <li>Client facing stateless session beans provided services such as transaction support
        and security for synchronous calls. Message driven beans provided asynchronous
        processing. Web service interfaces exposed for interoperability.
      </li>
      <li>The primary client was a Swing application. The codebase faced the usual numerous
        architectural challenges associated with substantial Swing applications. Involved
        heavily with overcoming these by the application of design patterns (such as
        hierarchical MVC), inversion of control, separation of concerns and component
        oriented modularisation.
      </li>
      <li>In the early days, lead one small web application using Struts.</li>
      <li>Designed web services (SOAP over HTTP) used to interface AMP and a media
        publication system written in .NET and Adobe middleware. Implemented with
        ActiveSOAP and XmlBeans. Developed technical interoperability specifications
        comprising WSDL, UML and XML schema for the offshore developers implementing
        these services.
      </li>
      <li>Responsible for build management using Ant. Moved to a modern declarative, modular
        build architecture to support modularisation. Factored monolithic application into
        modules and components. Replaced configuration system with flexible inversion of
        control using PicoContainer glued with Groovy scripting.
      </li>
      <li>Responsible for code management using CVS and Subversion. Maintaining multiple
        code streams and a large code base with offshore developers proved an interesting
        challenge.
      </li>
      <li>Installation and administration of Red Hat Enterprise Linux 5, Ubuntu and Solaris 10
        servers. Virtualisation using VMWare.
      </li>
      <li> Trained and mentored some of the new developers.</li>
    </ul>
  </section>
)

export default Short
