import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import RetailExpress from "../../components/retail_express"

const RetailExpressPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <RetailExpress/>
  </Layout>
)

export default RetailExpressPage
